export const PaymentStatus = {
  CREATED: 'created',
  REDIRECTED: 'redirected',
  INVOICING: 'invoicing',
  WAITING_FOR_WIRE_PAYMENT: 'waiting_for_wire_payment',
  POSTPAY: 'postpay',
  PURCHASED: 'purchased',
  REFUNDED: 'refunded',
  CANCELED: 'canceled',
  ERROR: 'error',
  FAILED: 'failed',
};

export const PaymentMethod = {
  ANONYMOUS: 'anonymous',
  SAVED_CARD: 'saved_card',
  GOOGLE_PAY: 'google_pay',
  APPLE_PAY: 'apple_pay',
  INVOICE_OFFER: 'invoice_offer',
  ONLINE: 'online',
  POSTPAY: 'postpay',
  TERMINAL: 'terminal',
  WIRE_TRANSFER: 'wire_transfer',
  WIRE_TRANSFER_WITH_GUARANTEE: 'wire_transfer_with_guarantee',
  CASH: 'cash',
  SBP: 'sbp',
};

export const PaymentType = {
  INVOICE_OFFER: 'invoice_offer',
  ONLINE: 'online',
  POSTPAY: 'postpay',
  TERMINAL: 'terminal',
  WIRE_PAYMENT: 'wire_payment',
  WIRE_PAYMENT_WITH_GUARANTEE: 'wire_payment_with_guarantee',
  CASH: 'cash',
  SBP: 'sbp',
};
